import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import App from './App';
import rootReducer from './reducers/rootReducer';
import * as serviceWorker from './serviceWorker';

// Create Redux store with thunk middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

// Initialize ReactDOM root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component wrapped with Redux Provider
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// Unregister service worker for now
serviceWorker.unregister();
