import React from 'react';
import Embed from '../UI/Embed';

const FavEmbeds = ({ embeds, upvoteEmbed, votedEmbeds = [] }) => {
    const embedList = embeds
        .filter(embed => votedEmbeds.includes(embed.id))
        .map(embed => (
            <Embed
                key={embed.id}
                embed={embed}
                upvoteEmbed={upvoteEmbed}
                isVoted={true}  // All embeds here are voted since we filter them
            />
        ));

    if (!embedList.length) {
        return <div className="zerofavcontainer"><div>You have not favorited any releases yet!</div></div>;
    }

    return <div className="embed-list">{embedList}</div>;
};

export default FavEmbeds;
