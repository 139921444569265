import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Releases from '../UI/Releases';

const Catalog = () => {
    // Using useSelector to access state directly from the Redux store
    const catalogEmbeds = useSelector((state) => state.catalogEmbeds);

    return (
        <div>
            <Helmet>
                <title>Featured Releases - Ambient Music</title>
                <meta
                    name="description"
                    content="Featured releases on Ambient Music. Ambient Music curates minimalist, drone, industrial, and dark ambient music for work and reflection."
                />
            </Helmet>
            <div></div>
            <Releases embeds={catalogEmbeds} />
        </div>
    );
};

export default Catalog;
