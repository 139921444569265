import React from 'react';
import Functions from '../Functions';

const Embed = ({ embed, upvoteEmbed, isVoted }) => {
    const iframeId = `iframe-${embed.id}`;
    const buttonStyle = { backgroundColor: "#141d26", border: "none", outline: "none" };
    const imgStyle = { width: "30px", height: "30px" };
    const imgSrc = isVoted ? "./lit.png" : "./unlit.png";
    const imgAlt = isVoted ? "favorited" : "favorite";

    const removePlaceholder = () => {
        const element = document.getElementById(iframeId);
        if (element) {
            element.classList.remove("placeholder");
        }
    };

    return (
        <div className="embed-container">
            <iframe
                id={iframeId}
                title={embed.id}
                className="iframe-embed placeholder"
                src={Functions.getIframeHtml(embed)}
                seamless
                onLoad={removePlaceholder}
            />
            <br />
            <button style={buttonStyle} title={imgAlt} onClick={() => upvoteEmbed(embed.id)}>
                <img
                    alt={imgAlt}
                    src={imgSrc}
                    style={imgStyle}
                    className="hvr-grow"
                />
            </button>
        </div>
    );
};

export default Embed;
