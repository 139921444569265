import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FavEmbeds from '../UI/FavEmbeds';
import { toggleVoteEmbed } from '../actions/embedActions';
import { Helmet } from 'react-helmet';

const Fav = () => {
    // Using useSelector to access state directly from the Redux store
    const catalogEmbeds = useSelector((state) => state.catalogEmbeds);
    const votedEmbeds = useSelector((state) => state.votedEmbeds);

    // Using useDispatch to get the dispatch function
    const dispatch = useDispatch();

    // Handler to dispatch the toggleVoteEmbed action
    const handleToggleVoteEmbed = (embedId) => {
        dispatch(toggleVoteEmbed(embedId));
    };

    return (
        <div>
            <Helmet>
                <title>Favorite Releases - Ambient Music</title>
                <meta
                    name="description"
                    content="Ambient Music curates minimalist, drone, industrial, and dark ambient music on the World Wide Web. Stream ambient music."
                />
            </Helmet>
            <div className="container">
                <FavEmbeds
                    embeds={catalogEmbeds}
                    upvoteEmbed={handleToggleVoteEmbed}
                    votedEmbeds={votedEmbeds}
                />
            </div>
        </div>
    );
};

export default Fav;
