import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Embeds from '../UI/Embeds';
import { useSelector, useDispatch } from 'react-redux';
import { toggleVoteEmbed } from '../actions/embedActions';  // Correct import
import { Helmet } from 'react-helmet';

const Home = () => {
  // Using useSelector to access the Redux state
  const catalogEmbeds = useSelector((state) => state.catalogEmbeds);
  const votedEmbeds = useSelector((state) => state.votedEmbeds);

  // Using useDispatch to get the dispatch function
  const dispatch = useDispatch();

  // State hooks for embeds, selected color, and infinite scroll status
  const [embeds, setEmbeds] = useState(catalogEmbeds.slice(0, 2));
  const [color, setColor] = useState('all');
  const [hasMore, setHasMore] = useState(true);

  // Effect to reset embeds when catalogEmbeds change
  useEffect(() => {
    setEmbeds(catalogEmbeds.slice(0, 2));
  }, [catalogEmbeds]);

  // Function to fetch more data for infinite scroll
  const fetchMoreData = () => {
    const index = embeds.length;
    if (index >= catalogEmbeds.length) {
      setHasMore(false);
      return;
    }
    setEmbeds((prevEmbeds) => [...prevEmbeds, ...catalogEmbeds.slice(index, index + 2)]);
  };

  // Function to filter embeds by color
  const selectColor = (selectedColor) => {
    setEmbeds(catalogEmbeds);
    setColor(selectedColor);
  };

  // Handler for toggling votes
  const handleToggleVoteEmbed = (embed) => {
    dispatch(toggleVoteEmbed(embed));
  };

  return (
    <div>
      <Helmet>
        <title>Ambient Music - For work and reflection</title>
        <meta
          name="description"
          content="Ambient Music curates minimalist, drone, industrial, and dark ambient music on the World Wide Web. Stream and discover new ambient music."
        />
      </Helmet>
      <div className="container">
        <div>
          <p className="page-title">ambient music for work and reflection</p>
        </div>
        <div className="color-picker">
          {['black', 'red', 'pink', 'purple', 'blue', 'green', 'yellow', 'white', 'gray'].map((colorName) => (
            <div
              key={colorName}
              className={`box ${colorName}`}
              onClick={() => selectColor(colorName)}
            ></div>
          ))}
        </div>
        <InfiniteScroll
          dataLength={embeds.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<p>Loading...</p>}
        >
          <Embeds
            embeds={embeds}
            color={color}
            upvoteEmbed={handleToggleVoteEmbed}  // Updated to use the local handler
            votedEmbeds={votedEmbeds}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Home;
