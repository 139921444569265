import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleVoteEmbed } from '../actions/embedActions';  // Correct import
import Embeds from '../UI/Embeds';  // Use the correct component for displaying embeds
import { Helmet } from 'react-helmet';

const Random = () => {
  // Accessing Redux state using useSelector
  const catalogEmbeds = useSelector((state) => state.catalogEmbeds);
  const votedEmbeds = useSelector((state) => state.votedEmbeds);

  // Getting the dispatch function
  const dispatch = useDispatch();

  // Local state to hold the randomly selected embed
  const [randomEmbed, setRandomEmbed] = useState(null);

  useEffect(() => {
    // Ensure catalogEmbeds contains data before selecting a random embed
    if (catalogEmbeds.length > 0) {
      // Select one random embed
      const randomIndex = Math.floor(Math.random() * catalogEmbeds.length);
      setRandomEmbed(catalogEmbeds[randomIndex]);
    }
  }, [catalogEmbeds]);

  // Handler to toggle vote on an embed
  const handleToggleVoteEmbed = (embed) => {
    dispatch(toggleVoteEmbed(embed));
  };

  return (
    <div>
      <Helmet>
        <title>Random Release - Ambient Music</title>
        <meta
          name="description"
          content="Discover a random ambient music selection curated from a vast catalog of minimalist, drone, industrial, and dark ambient music."
        />
      </Helmet>
      <div className="container">
        {randomEmbed ? (
          <Embeds
            embeds={[randomEmbed]}  // Pass only the single random embed
            color="all"  // Set to "all" to avoid additional filtering
            upvoteEmbed={handleToggleVoteEmbed}  // Use the local handler for toggling votes
            votedEmbeds={votedEmbeds}
          />
        ) : (
          <div>No embed available!</div>
        )}
      </div>
    </div>
  );
};

export default Random;
