export const toggleVoteEmbed = (embedId) => (dispatch) => {
    let votedEmbeds = JSON.parse(localStorage.getItem("votedEmbeds")) || [];

    if (votedEmbeds.includes(embedId)) {
        // Handle unvoting
        votedEmbeds = votedEmbeds.filter(id => id !== embedId);
        localStorage.setItem("votedEmbeds", JSON.stringify(votedEmbeds));
        dispatch({ type: 'UNVOTE_EMBED', embedId });
    } else {
        // Handle voting
        votedEmbeds.push(embedId);
        localStorage.setItem("votedEmbeds", JSON.stringify(votedEmbeds));
        dispatch({ type: 'VOTE_EMBED', embedId });
    }
};
