import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from './UI/Navigation';
import './App.css';
import Random from './components/Random';
import Fav from './components/Fav';
import Home from './components/Home';
import Catalog from './components/Catalog';
import About from './UI/About';
import Footer from './UI/Footer';

const App = () => {
  return (
    <BrowserRouter>
      <div className="App container">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/favorites" element={<Fav />} />
          <Route path="/random" element={<Random />} />
          <Route path="/about" element={<About />} />
          <Route path="/releases" element={<Catalog />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
