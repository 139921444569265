import React from 'react';
import Embed from '../UI/Embed';

const Embeds = ({ embeds, color, upvoteEmbed, votedEmbeds = [] }) => {
    const embedList = embeds
        .filter(embed => color === 'all' || embed.c === color)
        .map(embed => (
            <Embed
                key={embed.id}
                embed={embed}
                upvoteEmbed={upvoteEmbed}
                isVoted={votedEmbeds.includes(embed.id)}
            />
        ));

    return <div className="embed-list">{embedList.length > 0 ? embedList : <p>No embeds found.</p>}</div>;
};

export default Embeds;
