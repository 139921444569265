import catalog from '../data/catalog.json';

const initState = {
    catalogEmbeds: catalog.embeds,
    votedEmbeds: JSON.parse(localStorage.getItem("votedEmbeds")) || []
};

const rootReducer = (state = initState, action) => {
    switch (action.type) {
        case 'VOTE_EMBED':
            return {
                ...state,
                votedEmbeds: [...state.votedEmbeds, action.embedId]
            };
        case 'UNVOTE_EMBED':
            return {
                ...state,
                votedEmbeds: state.votedEmbeds.filter(id => id !== action.embedId)
            };
        default:
            return state;
    }
};

export default rootReducer;
